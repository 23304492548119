<template>
  <div class="simeon">
    <div class="simeon-wrapper">
      <div class="overlay">
        <div class="start">
          <div class="score" :class="{'score-success': success, 'score-fail': fail}">
            {{score}}
          </div>

          <button :disabled="started" @click="startGame()">
            START
          </button>
        </div>

        <div class="reset">
          <button :disabled="!started" @click="reset()">
            RESET
          </button>
        </div>
      </div>
      <div class="simeon-game-board">
        <div :key="space"
          @click="checkInput(i)"
          v-for="(space,i) in spaces"
          :class="[space.klass, {fail: fail, blink: blink == i || success}] "
          class="simeon-game-spaces">

          <div class="lite">
            <div class="key">
              {{space.key}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const getAudioContext = () => {
  let ctx = null, usingWebAudio = true;

  try {
    // Fix up for prefixing
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    ctx = new AudioContext();

    // context state at this time is `undefined` in iOS8 Safari
    if (usingWebAudio && ctx.state === 'suspended') {
      var resume = function () {
        ctx.resume();

        setTimeout(function () {
          if (ctx.state === 'running') {
            document.body.removeEventListener('touchend', resume, false);
          }
        }, 0);
      };
      document.body.addEventListener('touchend', resume, false);
    }

    return ctx

  } catch (e) {
    usingWebAudio = false;
    alert('Sorry, Web Audio API is not supported in this browser');
    return null;
  }
};

export default {
  name: 'App',
  components: {
  },

  data() {
    return {
      colors: ["green", "red", "blue", "yellow"],
      started: false,
      audioContext: null,
      oscMain: null,
      gainMain: null,
      volume: 60,
      count: 4,
      score: 0,
      blink: null,
      check: [],
      pattern: [],
      fail: false,
      success: false,
      playing: false,
      spaces: [
        {
          key: "Q",
          tone: 220.00,
          klass: 'piece-t-l'
        },
        {
          key: "W",
          tone: 261.63,
          klass: 'piece-t-r'
        },
        {
          key: "A",
          tone: 293.66,
          klass: 'piece-b-l'
        },
        {
          key: "S",
          tone: 329.63,
          klass: 'piece-b-r'
        },
      ]
    }
  },

  created() {
    window.addEventListener('keyup', (e) => {
      switch(e.key) {
        case "q":
          this.playOSC(this.spaces[0].tone)
          this.checkInput(0)
          break
        case "w":
          this.playOSC(this.spaces[1].tone)
          this.checkInput(1)
          break
        case "a":
          this.playOSC(this.spaces[2].tone)
          this.checkInput(2)
          break
        case "s":
          this.playOSC(this.spaces[3].tone)
          this.checkInput(3)
          break
      }
    })
  },

  mounted() {
  //const randomColor = "#"+((1<<24)*Math.random()|0).toString(16);
  //document.documentElement.style.setProperty('--main-bg-color', randomColor);
  },

  methods: {
    playOSC: function (freq = 440.0, type = 'triangle') {
      if (this.oscMain != null) {
        this.oscMain.stop();
        this.oscMain = null;
      }

      this.oscMain = null;
      this.oscMain = this.audioContext.createOscillator();

      this.oscMain.type = type;
      this.oscMain.frequency.value = freq;

      this.gainMain = this.gainMain || this.audioContext.createGain();
      this.gainMain.gain.value = this.volume / 100.0;

      this.oscMain.connect(this.gainMain);
      this.gainMain.connect(this.audioContext.destination);

      this.oscMain.start();
      this.oscMain.stop(this.audioContext.currentTime + 0.2);
    },

    checkInput(i) {
      if(!this.playing && this.started) {
        this.blink = i
        if(this.check.length < this.pattern.length) {
          this.playOSC(this.spaces[i].tone)
          this.check.push(i)
        }

        for(let i = 0; i < this.check.length; i++) {
          if(this.check[i] != this.pattern[i]) {
            this.blink = null
            this.fail = true
          }
        }

        if(!this.fail && (this.check.length == this.pattern.length)) {
          this.score += 1
          this.check = []
          this.success = true
          this.blink = null

          setTimeout(()=>{
            this.success = false
            this.fail = false
            this.addToPattern()
            this.playPattern()
          },200)
        }
      }
    },

    startGame() {
      this.started = true
      if(!this.audioContext) {
        this.audioContext = getAudioContext()
      }

      //this.playOSC(this.spaces[0].tone)

      this.reset()
    },

    reset() {
      this.fail = false
      this.success = false
      this.pattern = []
      this.check = []
      this.score = 0
      this.addToPattern()
      this.playPattern()
    },

    playPattern() {
      var i = 0
      this.playing = true

      var moves = setInterval(() => {
        this.blink = this.pattern[i]
        this.playOSC(this.spaces[this.pattern[i]].tone)
        i++

        setTimeout(() => {
          this.blink = null
        }, 500)

        if(i >= this.pattern.length) {
          clearInterval(moves)

          setTimeout(() => {
            this.blink = null
            this.playing = false
          }, 200)
        }
      },600)

    },

    addToPattern() {
      this.pattern.push(Math.floor(Math.random() * 4))
    },
  }
}
</script>

<style>
@font-face {
  font-family: 'digital';
  src: url('~@/assets/font/DIGITALDREAM.ttf');
}

html, body{
  overflow: hidden;
  font-family: 'digital';
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /*background: var(--main-bg-color);*/
  background:
  radial-gradient(black 15%, transparent 16%) 0 0,
  radial-gradient(black 15%, transparent 16%) 8px 8px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-color:#282828;
  background-size:16px 16px;
}


body {
  position: relative;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.simeon-wrapper {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: 640px;
  height: 100vw;
  max-height: 640px;
}

.simeon-game-board {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 75%;
  height: 75%;
  padding: 8px;
  border: 4px solid #232323;
  background: linear-gradient(to left, #2b2b2b, #464646);
  border-radius: 100%;
  box-shadow: 0 0 8px 2px black;
}

.overlay {
  background: linear-gradient(to bottom, #5b5b5b, #191919);
  border-radius: 100%;
  width: 30%;
  height: 30%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 6px;
  box-shadow: 1px 1px 6px 0px black;
  transform: translateX(-50%) translateY(-50%);
}

.score {
  background: black;
  border-radius: 6px;
  border-top: 2px solid #262626;
  width: 50%;
  height: 32px;
  font-size: 22px;
  line-height: 32px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  color: blue;
  text-shadow: 2px 2px 8px blue;
  transform: translateX(-50%) translateY(-50%);
}

.score-success {
  color: green;
  text-shadow: 2px 2px 8px green;
}

.score-fail {
  color: red;
  text-shadow: 2px 2px 8px red;
}

.simeon-game-spaces {
  box-sizing: border-box;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
  display: grid;
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 4px 2px black, inset -11px 0px 4px -2px #00000059, inset 11px 0px 4px 2px #ffffff6b;
}

.piece-t-l {
  background-color: #12cb56;
  border-top-left-radius: 100%;
}
.piece-t-r {
  background-color: #fd0010;
  border-top-right-radius: 100%;
}
.piece-b-l {
  background-color: #f1c200;
  border-bottom-left-radius: 100%;
}
.piece-b-r {
  background-color: #1264ca;
  border-bottom-right-radius: 100%;
}

.lite {
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
  color: #ffffffa8;
  box-sizing: border-box;
  border-radius: 100%;
  height: 50px;;
  width: 50px;
}

.blink {
  box-shadow: inset 0 0 70px 60px #ffffffa6, inset -11px 0px 4px -2px #00000059, inset 11px 0px 4px 2px #ffffff6b;
}

.start, .reset {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.start button, .reset button {
  font-family: 'digital';
  outline: none;
  font-size: 24px;
  font-weight: bold;
  height: 100%;
  background: linear-gradient(45deg, #262626, #ffffff29);
  text-shadow: -1px -1px 0px #838383;
  color: black;
  width: 100%;
}

.start button {
  border-radius: 100px 100px 0 0;
  border: 2px solid #262626;
  border-bottom: 1px solid #262626;
}

.start button:disabled, .reset button:disabled {
  color: #838383;
  font-weight: normal;
  text-shadow: -1px -1px 0px black;
}

.reset button {
  border-radius: 0 0 100px 100px;
  border: 2px solid #262626;
  border-top: 1px solid #262626;
}

.fail {
  background: #fd0010;
}

.success {
  /*background: #12cb56;*/
  background: #ffffffba;
}

.key {
  text-shadow: 0 0 5px black;
}

@media (max-height:640px) {
  .simeon-wrapper {
    max-height: 100%;
  }
}
@media (max-width:640px) {
  .simeon-wrapper {
    max-width: 100%;
  }

  .simeon-game-board {
    width: 90%;
    height: 90%;
  }
  .start button, .reset button {
    font-size: 14px;
  }
  .key {
    display: none;
  }
}
</style>
